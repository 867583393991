import React, { useEffect, useState } from "react";
import PersonIcon from "assets/User.png";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { useHistory } from "react-router-dom";
import { Typography } from "@mui/material";

const AuthPopup = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [user, setUser] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });
    return () => unsubscribe();
  }, []);

  let history = useHistory();

  function handleClickRoute(route) {
    history.push(route);
  }

  const handleSignOut = async () => {
    const auth = getAuth();
    await signOut(auth);
    handleClose();
  };

  const handleLogin = () => {
    // Aquí iría la lógica para iniciar sesión
    handleClickRoute("/LoginUser");
    handleClose();
  };

  const handleProfile = () => {
    handleClickRoute("/Profile");
    handleClose();
  };

  const handleRegister = () => {
    handleClickRoute("/RegisterUser");
    handleClose();
  };

  return (
    <div style={{ padding: "5px", display: "flex", alignItems: "center" }}>
      <div
        fontSize="large"
        style={{ display: 'flex', cursor: "pointer", margin: "5px", alignItems:'center' }}
        onClick={handleClick}
      >
        <img
          src={PersonIcon}
          alt="user"
          style={{ height: "23px", width: "23px", marginRight: '5px' }}
        />
        <Typography> inicia sesión</Typography>
      </div>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {user ? (
          <>
            <MenuItem onClick={handleProfile}>Ver Perfil</MenuItem>
            <MenuItem onClick={handleSignOut}>Cerrar sesión</MenuItem>
          </>
        ) : (
          <>
            <MenuItem onClick={handleLogin}>Iniciar sesión</MenuItem>
            <MenuItem onClick={handleRegister}>Registrarse</MenuItem>
          </>
        )}
      </Menu>
    </div>
  );
};

export default AuthPopup;
