import { Button, Divider } from "@mui/material";
import Gallery from "components/Gallery/Gallery";

export default function TipsSection({ cols }) {
  const titleStyle = {
    fontFamily: "Nunito Sans, sans-serif",
    fontSize: "60px",
    fontWeight: "700",
    marginBottom: "10px",
    marginTop: "10px",
  };

  return (
    <div
      style={{
        marginRight: "10%",
        marginLeft: "10%",
        paddingTop: "50px",
        paddingBottom: "100px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h2 style={titleStyle}>TIPS PARA TU VEHÍCULO</h2>
      <Divider
        sx={{ my: 2, borderBottomWidth: 2, bgcolor: "black", width: "80%" }}
      />
      <Gallery />
      <Button variant="outlined" onClick={() => window.open('/Tips')}>Ver Más Tips</Button>
    </div>
  );
}
