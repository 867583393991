import { formatCurrency, formatTicket } from "helpers/helpers";
import { sendEmail } from "utils/API/EmailAPI";
import { getNumber } from "utils/API/NumberOfSales";

export async function sendClientEmail(clientData, products) {
  const templateId = "d-f4428ee0c953434caf8ca1420277bc2e";
  const ticket = await getNumber();

  const subtotal_price = products.reduce((total, producto) => {
    return total + (producto.price * producto.quantity);
  }, 0);

  const travel_price = subtotal_price > 549.0 ? 0 : 149;

  const orders = products.map(product => {
    return {
      name: product.name,
      quantity: product.quantity,
      variants: product.description,
      price: formatCurrency(product.price * product.quantity),
    }
  });

  const data = {
    pedido: formatTicket(ticket),
    urlSell: `https://eloquent-bartik-1847d8.netlify.app/Profile?sell=${ticket-1}`,
    subtotal_price: formatCurrency(subtotal_price),
    travel_price: formatCurrency(travel_price),
    total_price: formatCurrency(subtotal_price + travel_price),
    ...clientData,
    orders
  };

  const response = await sendEmail('afinazone@outlook.com', clientData.email, templateId, data);

  return response;
}

export async function sendAfinazoneEmail(clientData, products) {
  const templateId = "d-fa6fbf7dcb424b91970df0cd7516a8e3";
  const ticket = await getNumber();

  const subtotal_price = products.reduce((total, producto) => {
    return total + (producto.price * producto.quantity);
  }, 0);

  const travel_price = subtotal_price > 549.0 ? 0 : 149;

  const orders = products.map(product => {
    return {
      name: product.name,
      quantity: product.quantity,
      variants: product.description,
      price: formatCurrency(product.price * product.quantity),
    }
  });

  const data = {
    pedido: formatTicket(ticket),
    urlSell: `https://eloquent-bartik-1847d8.netlify.app/Ventas#${ticket-1}`,
    subtotal_price: formatCurrency(subtotal_price),
    travel_price: formatCurrency(travel_price),
    total_price: formatCurrency(subtotal_price + travel_price),
    ...clientData,
    orders
  };

  const response = await sendEmail('auto-tek@outlook.com', 'afinazone@outlook.com', templateId, data);

  return response;
}
