import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Box,
  Divider,
} from "@mui/material";

const ProductCard = ({ products }) => {
  const titleStyle = {
    fontFamily: "Nunito Sans, sans-serif",
    fontSize: "60px",
    fontWeight: "700",
    marginBottom: "10px",
    marginTop: "10px",
  };

  return (
    <Card sx={{ maxWidth: "100%", m: 2 }}>
      <CardContent>
        <h2 style={titleStyle}>MÁS VENDIDOS</h2>
        <Divider sx={{ my: 2, borderBottomWidth: 2, bgcolor: "black" }} />
        <Grid container spacing={2} sx={{ my: 2 }}>
          {products.map((product, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <a
                  href={`/Producto?product=${product.type}&id=${product.id}`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <CardMedia
                    component="img"
                    height="250px"
                    width="250px"
                    image={product.url}
                    alt={product.name}
                  />
                </a>
                <Typography
                  gutterBottom
                  variant="h7"
                  component="div"
                  align="center"
                >
                  {product.name}
                </Typography>
                {product.disableOil && (
                  <Typography variant="h3" component="div" align="center">
                    ${product.price}
                  </Typography>
                )}
                {!product.disableOil && (
                  <Typography variant="h3" component="div" align="center">
                    ${Math.min(...product.liters.map((item) => item.price))}
                  </Typography>
                )}
                <Typography variant="body3" align="center">
                  <Box component="span" color="error.main">
                    envío GRATIS
                  </Box>
                  <Box component="span">
                    &nbsp;apartar de $549 en tu carrito
                  </Box>
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Divider sx={{ my: 2, borderBottomWidth: 2, bgcolor: "black" }} />
      </CardContent>
    </Card>
  );
};

export default ProductCard;
