import Shipping from "assets/Shipping.png";

export default function ShoppingInfo() {
  return (
    <div
      style={{
        marginRight: "5%",
        marginLeft: "5%",
        paddingBottom: "20px",
      }}
    >
      <img src={Shipping} style={{ width: "90%" }} alt='img'></img>
    </div>
  );
}
