/* Routing */
import { useHistory } from "react-router-dom";

import logo from "assets/afinazoneTransparente.png";

import facebook from "assets/fb.png";
import instagram from "assets/ig.png";
import tiktok from "assets/tiktok.png";
import { COLORS } from "utils/Constants/Colors";
import { openInNewTab } from "helpers/helpers";

const socialMediaContainerStyle = {
  display: "flex",
  alignItems: "center",
  marginTop: "10px",
  fontWeight: "900",
  textDecoration: "underline",
  cursor: "pointer",
};

export default function Footer() {
  let history = useHistory();
  const openInTab = (url) => {
    history.push(url);
  };

  return (
    <div
      style={{
        background: COLORS.back,
        display: "flex",
        justifyContent: "space-around",
        paddingTop: "20px",
        paddingBottom: "60px",
        color: "white",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <img src={logo} alt="afinazone" style={{ height: "50px" }} />
        <div
          style={socialMediaContainerStyle}
          onClick={() => openInNewTab("https://www.facebook.com/afinazonemx/")}
        >
          <img
            src={facebook}
            alt="facebook"
            style={{ height: "25px", width: "25px", marginRight: "10px" }}
            onClick={() => openInTab("https://www.facebook.com/afinazonemx/")}
          />
          <i>Afinazone MX.</i>
        </div>

        <div
          style={socialMediaContainerStyle}
          onClick={() =>
            openInNewTab(
              "https://instagram.com/afinazonemx?utm_medium=copy_link"
            )
          }
        >
          <img
            src={instagram}
            alt="instagram"
            style={{ height: "25px", width: "25px", marginRight: "10px" }}
          />
          <i>afinazonemx</i>
        </div>

        <div
          style={socialMediaContainerStyle}
          onClick={() => openInNewTab("https://www.tiktok.com/@afinazonemx")}
        >
          <img
            src={tiktok}
            alt="tiktok"
            style={{ height: "25px", width: "25px", marginRight: "10px" }}
          />
          <i>afinazonemx</i>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h2> PRODUCTOS </h2>
        <div
          style={socialMediaContainerStyle}
          onClick={() => openInTab("/Categoria?section=KAT")}
        >
          <i>Kit de Afinación de Transmisión</i>
        </div>
        <div
          style={socialMediaContainerStyle}
          onClick={() => openInTab("/Categoria?section=Ac")}
        >
          <i>Aceites</i>
        </div>
        <div
          style={socialMediaContainerStyle}
          onClick={() => openInTab("/Categoria?section=Cart")}
        >
          <i>Cárter</i>
        </div>
        <div
          style={socialMediaContainerStyle}
          onClick={() => openInTab("/Categoria?section=Enf")}
        >
          <i>Enfriadores</i>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        <h2> AYUDA </h2>

        <div
          style={socialMediaContainerStyle}
          onClick={() => openInTab("/TerminosYCondiciones")}
        >
          <i>Términos y Condiciones</i>
        </div>
        <div
          style={socialMediaContainerStyle}
          onClick={() => openInTab("/AvisoDePrivacidad")}
        >
          <i>Aviso de Privacidad</i>
        </div>
        <div
          style={socialMediaContainerStyle}
          onClick={() => openInTab("/PoliticasDeCompra")}
        >
          <i>Políticas de Compra</i>
        </div>
        <div
          style={socialMediaContainerStyle}
          onClick={() => openInTab("/")}
        >
          <i>Facturación</i>
        </div>
        <div
          style={socialMediaContainerStyle}
          onClick={() => openInNewTab("https://wa.me/524613126256")}
        >
          <i>Atención al cliente</i>
        </div>
      </div>
    </div>
  );
}
