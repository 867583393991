import { useEffect, useState } from 'react';

/* Material UI */
import { Grid } from '@material-ui/core';

/* Pictures */
import afinazone from 'assets/iconos/afinazone.png';

/* API */
import { getBrandsAPI } from 'utils/API/Brands';

import Box from '@mui/material/Box';

export default function Cars(props) {

  const [availableBrands, setAvailableBrands] = useState(null);
  const [notAvailableBrands, setNotAvailableBrands] = useState(null);
  const [urls, setUrls] = useState(null);

  useEffect(() => {

    const go = async () => {
      const brands = await getBrandsAPI();
      let newUrls = {}
      brands.forEach((doc) => {
        const brand = { ...doc.data() };
        newUrls[brand.name] = brand.url;
      });
      let checkBrands = {};
      brands.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        checkBrands[doc.data().name] = false;
      });

      for (var product of props.products) {
        checkBrands[product.brand] = true;
      }
      let avBrands = [];
      let notAvBrands = [];

      for (var check of Object.keys(checkBrands)) {
        checkBrands[check] ? avBrands.push(check) : notAvBrands.push(check);
      }
      setAvailableBrands(avBrands);
      setNotAvailableBrands(notAvBrands);
      setUrls(newUrls);
    }
    if (props.products !== null) {
      go();
    }
  }, [props.products]);

  return (
    <Grid container spacing={3} >
      {availableBrands !== null && availableBrands.map((item, idx) => {
        return (
          <Grid key={idx} item xs={3} lg={3}>
            <div
              style={{
                width: '100%',
                paddingBottom: '10%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box
                componen='span'
                sx={{
                  fontSize: { xs: '12px', md: '20px' },
                }}
                style={{
                  marginBottom: '15px',
                }}
              >
                {item}
              </Box>
              <img
                src={urls !== null ? urls[item] : afinazone}
                alt='marca'
                style={{ height: '70%', width: '70%', cursor: 'pointer' }}
                onClick={() => {
                  localStorage.setItem('car', item);
                  props.setOptionSection('model');
                  props.setBrand(item);
                }}
                className="zoom-on-hover"
              />
            </div>
          </Grid>
        );
      })}
      <Box
        component={Grid}
        sx={{
          fontSize: { xs: '20px', md: '30px' },
        }}
        key={'not brands'} item sm={12}
        style={{ textAlign: 'left', textDecoration: 'underline', fontFamily: "Nunito Sans, sans-serif" }}
      >
        No disponibles
      </Box>
      {
        notAvailableBrands !== null && notAvailableBrands.map((item, idx) => {
          return (
            <Grid key={idx} item xs={3} lg={3}>
              <div
                style={{
                  width: '100%',
                  paddingBottom: '20px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Box
                  componen='span'
                  sx={{
                    fontSize: { xs: '12px', md: '20px' },
                  }}
                  style={{
                    marginBottom: '15px',
                  }}
                >
                  {item}
                </Box>
                <img
                  src={urls !== null ? urls[item] : afinazone}
                  alt='marca'
                  style={{ height: '70%', width: '70%', cursor: 'pointer' }}
                  className="zoom-on-hover"
                />
              </div>
            </Grid>
          );
        })
      }
    </Grid >
  );
}