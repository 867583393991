import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./latido.css";
import CurrencyFormat from "react-currency-format";

/*helpers*/
import { useQuery, convertToShoppingCartElement } from "helpers/helpers";

import { getProductAPI } from "utils/API/Product";
import { getPreviewsImagesUrlFromProduct } from "utils/API/Pictures";

/* Components */
import Paypal from "../../components/PayPal";
import { Button, Card } from "@material-ui/core";

/* Sections */
import PayInfo from "./Sections/PayInfo";

/* Icons */
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import protectedShopping from "assets/img/compraProtegida.png";

import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { addNewSell } from "utils/API/VentasAPI";
import { sendClientEmail, sendAfinazoneEmail } from "utils/EmailSender/EmailSender";
import { Typography } from "@mui/material";

const whatsapp_float = {
  backgroundColor: "#25d366",
  padding: "15px",
  color: "#FFF",
  borderRadius: "50px",
  fontSize: "28px",
  boxShadow: "2px 2px 3px #999",
  marginRight: "10px",
  display: "flex",
};

const container = {
  paddingLeft: "10%",
  paddingRight: "10%",
  paddingTop: "100px",
  minHeight: "60vh",
  fontSize: "40px",
  textAlign: "left",
};

const paybutton = {
  width: "40%",
};
const paybuttonMobile = {
  width: "100%",
};

const payinfo = {
  width: "60%",
};

const payinfoMobile = {
  width: "100%",
};

const labelStyle = {
  fontSize: "20px",
};

const fieldStyle = {
  marginBottom: "20px",
  maxWidth: "500px",
};

const buttonStyle = {
  width: "100%",
  marginTop: "30px",
  marginBottom: "30px",
  height: "50px",
  maxWidth: "300px",
};

const text = {
  fontFamily: "Poppins, sans-serif",
  fontWeight: "300",
};

function Producto() {
  const [purchase, setPurchase] = useState(null);

  let query = useQuery();

  const [selected, setSelected] = React.useState(0);

  const [data, setData] = useState(null);
  const [dataURL, setDataURL] = useState(null);
  const [pay, setPay] = useState(0);

  /* Formulario */

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [street, setStreet] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [colony, setColony] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [addressReference, setAddressReference] = useState("");
  const [phone, setPhone] = useState("");
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const createSellOrder = async () => {
    function formatDateToDDMMYYYYHHMM() {
      const date = new Date();

      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");

      return `${day}-${month}-${year} ${hours}:${minutes}`;
    }

    const date = formatDateToDDMMYYYYHHMM();

    const products =
      purchase !== null
        ? purchase.map((item) => {
            return {
              quantity: item.amount,
              description: item.description,
              name: item.name,
              type: item.type,
              uuid: item.id,
              price: item.price,
            };
          })
        : [];

    const ok = await addNewSell({
      realDate: new Date(),
      date,
      name,
      email,
      street,
      postalCode,
      colony,
      city,
      state,
      country,
      phone,
      referenceAddress: addressReference,
      status: "pendiente",
      products,
    });

    if (ok) {
      alert("orden creada correctamente");

      const clientData = {
        name,
        street,
        reference: addressReference,
        neighbor: colony,
        postal_code: postalCode,
        city,
        state,
        country,
        phone,
        email,
      };
      await sendClientEmail(clientData, products);
      var emailStatus = await sendAfinazoneEmail(clientData, products);

      if (emailStatus.success) {
        alert("email enviado correctamente, revisar spam");
      } else {
        alert("no pudo ser enviado el email");
      }
    } else {
      alert(
        "orden pagada pero no creada, contactar a soporte para envío correcto de su orden"
      );
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const validateFields = () => {
    if (phone.length < 10) {
      alert("telefono incorrecto");
      return;
    } else if (!name) {
      alert("indica algún nombre");
      return;
    } else if (!street) {
      alert("indica alguna calle");
      return;
    } else if (!postalCode) {
      alert("indica algún código postal");
      return;
    } else if (!colony) {
      alert("indica alguna colonia");
      return;
    } else if (!city) {
      alert("indica alguna ciudad");
      return;
    } else if (!state) {
      alert("indica algún estado");
      return;
    } else if (!country) {
      alert("indica algún país");
      return;
    } else if (!addressReference) {
      alert("indica alguna referencia de domicilio");
      return;
    } else if (!email || !validateEmail(email)) {
      alert("indica un email válido");
      return;
    }
    setPay(2);
  };

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe || isRightSwipe) {
      if (isRightSwipe) setSelected(Math.max(0, selected - 1));
      else setSelected(Math.min(dataURL?.length - 1, selected + 1));
    }
  };

  const validatePhone = (phone) => {
    if (phone.length > 10) return;
    const validNumber = new RegExp("^[0-9]*$");

    if (validNumber.test(phone)) setPhone(phone);
  };

  useEffect(() => {
    async function go() {
      let product = await getProductAPI(query.get("id"), query.get("product"));
      let picturesURL = await getPreviewsImagesUrlFromProduct(
        query.get("id"),
        query.get("product")
      );
      setData({ ...product, id: query.get("id"), type: query.get("product") });
      setDataURL(picturesURL);
    }
    go();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (purchase === null) {
    return (
      <div
        style={{
          paddingLeft: "30px",
          paddingRight: "30px",
          paddingTop: "30px",
          paddingBottom: "30px",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <PictureSelector
            picturesURL={dataURL}
            handleSelector={(index) => setSelected(index)}
          />
          <Box
            sx={{
              width: { xs: "100%", md: "750px" },
              display: { xs: "block", md: "flex" },
            }}
            component="div"
            style={{
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              component="h2"
              sx={{
                display: { xs: "block", md: "none" },
                fontSize: { xs: "15px" },
              }}
            >
              {data?.name}
            </Box>
            {dataURL !== null && (
              <Box
                component="img"
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
                onTouchEnd={onTouchEnd}
                src={dataURL[selected]}
                alt="car"
                sx={{
                  width: { xs: "100%", md: "500px" },
                  height: { xs: "250px", md: "500px" },
                }}
                style={{
                  cursor: "pointer",
                }}
              />
            )}
            <Box
              sx={{
                display: { xs: "block", md: "none" },
              }}
              style={{ fontSize: "13px" }}
            >
              {selected + 1} / {dataURL?.length}
            </Box>
            {/* mobile */}
            <Box
              component="div"
              sx={{
                display: { xs: "flex", md: "none" },
              }}
              style={{
                marginTop: "15px",
                width: "100%",
                backgroundColor: "#d7d7d7",
                border: "1px solid #797979",
                borderRadius: "30px",
              }}
            >
              <ProductInfoMobile
                data={data}
                setPurchase={setPurchase}
                setPay={setPay}
              />
            </Box>
            <Box
              sx={{
                display: { xs: "flex", md: "flex" },
                width: { xs: "100%", md: "500px" },
                fontSize: { xs: "12px", md: "15px" },
              }}
              component={Card}
              style={{ marginTop: "30px", marginBottom: "30px" }}
            >
              <p
                style={{
                  whiteSpace: "pre-line",
                  textAlign: "left",
                  padding: "20px",
                  ...text,
                }}
              >
                {data?.description}
              </p>
            </Box>
            CÓDIGO UPC: {data?.upcCode}
          </Box>

          <Box
            component="div"
            sx={{
              display: { xs: "none", md: "flex" },
            }}
            style={{
              height: "100%",
              width: "35%",
              backgroundColor: "white",
            }}
          >
            <ProductInfo
              data={data}
              setPurchase={setPurchase}
              setPay={setPay}
            />
          </Box>
        </div>
      </div>
    );
  } else if (pay === 1) {
    return (
      <div style={container}>
        <ArrowBackIcon
          style={{ fontSize: "30px", marginRight: "10px", cursor: "pointer" }}
          onClick={() => setPurchase(null)}
        />
        Información de envío
        <hr />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p style={labelStyle}> Nombre </p>
          <TextField
            id="referencia-name"
            placeholder="Nombre de quién recibe el producto"
            variant="filled"
            fullWidth
            style={fieldStyle}
            value={name}
            onChange={(e) => setName(e.target.value)}
            autoComplete="name"
          />

          <p style={labelStyle}> Correo Electrónico </p>
          <TextField
            id="referencia-email"
            placeholder="Email"
            variant="filled"
            fullWidth
            style={fieldStyle}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <p style={labelStyle}> Número de Teléfono</p>
          <TextField
            id="referencia-phone"
            placeholder="Número telefónico"
            variant="filled"
            fullWidth
            style={fieldStyle}
            value={phone}
            onChange={(e) => validatePhone(e.target.value)}
            name="phone"
            autoComplete="phone"
          />

          <p style={labelStyle}> Calle y número </p>
          <TextField
            id="referencia-street"
            placeholder="EJ: PASEO DE LA REFORMA #163B"
            variant="filled"
            fullWidth
            style={fieldStyle}
            value={street}
            onChange={(e) => setStreet(e.target.value)}
          />

          <p style={labelStyle}> Código postal </p>
          <TextField
            id="referencia-postalCode"
            placeholder="EJ: 066600"
            variant="filled"
            fullWidth
            style={fieldStyle}
            value={postalCode}
            onChange={(e) => setPostalCode(e.target.value)}
          />

          <p style={labelStyle}> Colonia </p>
          <TextField
            id="referencia-colony"
            placeholder="EJ: LINDAVISTA"
            variant="filled"
            fullWidth
            style={fieldStyle}
            value={colony}
            onChange={(e) => setColony(e.target.value)}
            name="address2"
            autoComplete="address-level2"
          />

          <p style={labelStyle}> Ciudad </p>
          <TextField
            id="referencia-city"
            placeholder="EJ: Monterrey"
            variant="filled"
            fullWidth
            style={fieldStyle}
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />

          <p style={labelStyle}> Estado </p>
          <TextField
            id="referencia-state"
            placeholder="EJ: Nuevo León"
            variant="filled"
            fullWidth
            style={fieldStyle}
            value={state}
            onChange={(e) => setState(e.target.value)}
          />

          <p style={labelStyle}> País </p>
          <TextField
            id="country"
            name="country"
            placeholder="EJ: México"
            variant="filled"
            fullWidth
            style={fieldStyle}
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            autoComplete="country"
          />

          <p style={labelStyle}> Referencia de domicilio</p>
          <TextField
            id="referencia-domicilio"
            placeholder="Referencia del domicilio a ingresar"
            multiline
            variant="filled"
            fullWidth
            rows={4}
            onChange={(e) => setAddressReference(e.target.value)}
            style={fieldStyle}
            value={addressReference}
          />

          <Button
            style={buttonStyle}
            color="primary"
            variant="contained"
            onClick={() => {
              validateFields();
            }}
          >
            Proceder al pago
          </Button>
        </div>
      </div>
    );
  } else {
    return (
      <div style={container}>
        <ArrowBackIcon
          style={{ fontSize: "30px", marginRight: "10px", cursor: "pointer" }}
          onClick={() => setPay(1)}
        />
        Comprar Producto
        <hr />
        <Box component="div" sx={{ display: { xs: "none", md: "flex" } }}>
          <div style={paybutton}>
            <Paypal items={purchase} createSellOrder={createSellOrder} />
          </div>
          <div style={payinfo}>
            <PayInfo items={purchase} price={purchase[0].price} />
          </div>
        </Box>
        <Box component="div" sx={{ display: { xs: "block", md: "none" } }}>
          <div style={payinfoMobile}>
            <PayInfo items={purchase} price={purchase[0].price} />
          </div>
          <div style={paybuttonMobile}>
            <Paypal items={purchase} createSellOrder={createSellOrder} />
          </div>
        </Box>
      </div>
    );
  }
}

export default Producto;

function PictureSelector({ picturesURL, handleSelector }) {
  return (
    <Box
      component="div"
      sx={{ display: { xs: "none", md: "flex" } }}
      style={{
        height: "100%",
        width: "15%",
        margin: "10px",
        borderRight: "1px solid #797979",
        flexDirection: "column",
        alignItems: "flex-end",
      }}
    >
      {picturesURL !== null &&
        picturesURL.map((item, index) => {
          return (
            <img
              src={item}
              onClick={() => handleSelector(index)}
              alt="car"
              style={{
                paddingTop: "6px",
                cursor: "pointer",
                height: "100px",
                width: "100px",
              }}
            />
          );
        })}
    </Box>
  );
}

function ProductInfo({ data, setPurchase, setPay }) {
  if (data === null) {
    return null;
  }

  function buyProduct(price, description) {
    var newItem = {
      id: data.id,
      type: data.type,
      description: description ? description + " litros" : "",
      price: price,
      amount: 1,
      name: data.name,
    };
    setPay(1);
    setPurchase([newItem]);
  }

  function addToShoppingCart(price, description) {
    function getItem() {
      var newItem = {
        id: data.id,
        type: data.type,
        description: description ? description + " litros" : "",
        price: price,
        amount: 1,
        name: data.name,
      };
      newItem = JSON.stringify(newItem);
      return newItem;
    }

    const shoppingCart = convertToShoppingCartElement();
    if (shoppingCart) {
      if (
        !shoppingCart.filter((item) => {
          return (
            item.id === data.id &&
            (description ? item.description === description + " litros" : true)
          );
        }).length
      ) {
        const newItem = getItem();
        let newShoppingCart = [
          ...shoppingCart.map((item) => JSON.stringify(item)),
          newItem,
        ];
        newShoppingCart = newShoppingCart.join("+|+");
        localStorage.setItem("carrito", newShoppingCart);
        alert("Se agregó al carrito");
      } else alert("Este elemento ya está en tu carrito");
    } else {
      const newItem = getItem();
      let newShoppingCart = [newItem];
      newShoppingCart = newShoppingCart.join("+|+");
      localStorage.setItem("carrito", newShoppingCart);
      alert("Se agregó al carrito");
    }
  }

  const rowMini = {
    paddingLeft: "15%",
    paddingRight: "15%",
    marginBottom: "20px",
    fontFamily: "Poppins, sans-serif",
    textAlign: "left",
  };

  const infoTitle = {
    fontFamily: "Poppins, sans-serif",
    fontWeight: "300",
    fontSize: "35px",
  };

  return (
    <div style={{ padding: "10px" }}>
      <Box
        component="h2"
        sx={{ display: { xs: "none", md: "block" } }}
        style={{ ...infoTitle, fontStyle: "italic" }}
      >
        {data.name}
      </Box>

      {data.disableOil && (
        <div>
          <p style={{ fontSize: "45px", fontFamily: "Nunito, sans-serif" }}>
            ${" "}
            <CurrencyFormat
              value={data.price}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              displayType={"text"}
              style={{ fontFamily: "Nunito, sans-serif" }}
            />
          </p>
          <Button
            variant="contained"
            color="primary"
            style={{
              margin: "10px",
              borderRadius: "20px",
              fontFamily: "Nunito, sans-serif",
              fontStyle: "italic",
              fontWeight: "900",
            }}
            onClick={() => buyProduct(data.price, null)}
          >
            {" "}
            comprar Ahora{" "}
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{
              margin: "10px",
              borderRadius: "20px",
              fontFamily: "Poppins, sans-serif",
              fontStyle: "italic",
              fontWeight: "1000",
            }}
            onClick={() => addToShoppingCart(data.price, null)}
          >
            Agregar al carrito
          </Button>
        </div>
      )}
      {!data.disableOil && (
        <div>
          {data.liters.map((item) => {
            return (
              <div>
                <p style={{ fontSize: "25px" }}>
                  Precio: ${" "}
                  <CurrencyFormat
                    value={item.price}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    displayType={"text"}
                  />
                  , {item.liters} litros
                </p>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: "10px" }}
                  onClick={() => buyProduct(item.price, item.liters)}
                >
                  {" "}
                  comprar Ahora{" "}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: "10px" }}
                  onClick={() => addToShoppingCart(item.price, item.liters)}
                >
                  Agregar al carrito
                </Button>
              </div>
            );
          })}
        </div>
      )}

      <Typography variant="body3" align="center">
        <Box component="span" color="error.main">
          envío GRATIS
        </Box>
        <Box component="span">&nbsp;apartar de $549 en tu carrito</Box>
      </Typography>

      <div
        style={{
          paddingLeft: "15%",
          paddingRight: "15%",
          marginBottom: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={protectedShopping}
          alt="compra protegida"
          style={{ height: "30px", width: "30px", margin: "10px" }}
        />
        <Link
          to="/EnviosyPagos"
          style={{ color: "blue", textDecoration: "none" }}
        >
          Compra protegida
        </Link>
      </div>

      <div style={rowMini}>
        El tiempo de entrega del producto estimado es de 2 a 5 días hábiles,
        este puede variar dependiendo el domicilio
      </div>

      <div style={rowMini}>
        Si desea factura favor de enviar sus datos fiscales al correo{" "}
        <b>afinazone@outlook.com</b>
      </div>

      <div style={{ ...rowMini, textAlign: "center" }}>
        ¿TIENES DUDAS SOBRE EL ENVÍO, LA COMPATIBILIDAD O DISPONIBILIDAD DEL
        PRODUCTO?
      </div>

      <div style={{ ...rowMini, display: "flex", justifyContent: "center" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <a
            href="https://wa.me/524613126256 "
            style={whatsapp_float}
            target="_blank"
            rel="noopener noreferrer"
          >
            <WhatsAppIcon style={{ height: "50px", width: "50px" }} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{ fontFamily: "Poppins, sans-serif", fontSize: "15px" }}
              >
                Atención a clientes
              </div>
              <div
                style={{
                  fontFamily: "Poppins, sans-serif",
                  textDecoration: "none",
                  fontSize: "25px",
                }}
              >
                461-312-6256
              </div>
            </div>
          </a>
        </div>
      </div>

      <div style={{ ...rowMini, textAlign: "center" }}>
        Para más detalles{" "}
        <Link
          to="/EnviosyPagos"
          style={{ color: "#E21608", textDecoration: "none" }}
        >
          click aquí
        </Link>
      </div>
    </div>
  );
}

function ProductInfoMobile({ data, setPurchase, setPay }) {
  if (data === null) {
    return null;
  }

  function buyProduct(price, description) {
    var newItem = {
      id: data.id,
      type: data.type,
      description: description ? description + " litros" : "",
      price: price,
      amount: 1,
      name: data.name,
    };
    setPay(1);
    setPurchase([newItem]);
  }

  function addToShoppingCart(price, description) {
    function getItem() {
      var newItem = {
        id: data.id,
        type: data.type,
        description: description ? description + " litros" : "",
        price: price,
        amount: 1,
        name: data.name,
      };
      newItem = JSON.stringify(newItem);
      return newItem;
    }

    const shoppingCart = convertToShoppingCartElement();
    if (shoppingCart) {
      if (
        !shoppingCart.filter((item) => {
          return (
            item.id === data.id &&
            (description ? item.description === description + " litros" : true)
          );
        }).length
      ) {
        const newItem = getItem();
        let newShoppingCart = [
          ...shoppingCart.map((item) => JSON.stringify(item)),
          newItem,
        ];
        newShoppingCart = newShoppingCart.join("+|+");
        localStorage.setItem("carrito", newShoppingCart);
        alert("Se agregó al carrito");
      } else alert("Este elemento ya está en tu carrito");
    } else {
      const newItem = getItem();
      let newShoppingCart = [newItem];
      newShoppingCart = newShoppingCart.join("+|+");
      localStorage.setItem("carrito", newShoppingCart);
      alert("Se agregó al carrito");
    }
  }

  const rowMini = {
    paddingLeft: "3%",
    paddingRight: "3%",
    marginBottom: "10px",
    fontSize: "12px",
  };

  const buttonStyle = {
    margin: "10px",
    fontSize: "10px",
    fontWeight: "900",
    height: "30px",
    width: "100px",
  };

  return (
    <div style={{ padding: "10px", fontSize: "15px" }}>
      {data.disableOil && (
        <div style={{ display: "flex" }}>
          <p style={{ fontSize: "25px" }}>
            Precio: ${" "}
            <CurrencyFormat
              value={data.price}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              displayType={"text"}
            />
          </p>
          <div>
            <Button
              variant="contained"
              color="primary"
              style={buttonStyle}
              onClick={() => buyProduct(data.price, null)}
            >
              {" "}
              Comprar Ahora{" "}
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={buttonStyle}
              onClick={() => addToShoppingCart(data.price, null)}
            >
              <ShoppingCartIcon></ShoppingCartIcon>
            </Button>
          </div>
        </div>
      )}
      {!data.disableOil && (
        <div>
          {data.liters.map((item) => {
            return (
              <div style={{ display: "flex", marginBottom: "20px" }}>
                <div>
                  <div style={{ fontSize: "18px" }}>
                    Precio: ${" "}
                    <CurrencyFormat
                      value={item.price}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      displayType={"text"}
                    />
                  </div>
                  <div style={{ fontSize: "18px" }}>{item.liters} litros</div>
                </div>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    style={buttonStyle}
                    onClick={() => buyProduct(item.price, item.liters)}
                  >
                    {" "}
                    comprar Ahora{" "}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={buttonStyle}
                    onClick={() => addToShoppingCart(item.price, item.liters)}
                  >
                    <ShoppingCartIcon></ShoppingCartIcon>
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div style={rowMini}>El precio incluye IVA</div>

      <div style={rowMini}>
        ¡Envío GRATIS a partir de $549.00 en tu carrito!
      </div>

      <div
        style={{
          paddingLeft: "15%",
          paddingRight: "15%",
          marginBottom: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={protectedShopping}
          alt="compra protegida"
          style={{ height: "30px", width: "30px", margin: "10px" }}
        />
        <Link
          to="/EnviosyPagos"
          style={{ color: "blue", textDecoration: "none" }}
        >
          Compra protegida
        </Link>
      </div>

      <div style={rowMini}>
        El tiempo de entrega del producto estimado es de 2 a 5 días hábiles,
        este puede variar dependiendo el domicilio
      </div>

      <div style={rowMini}>
        Si tiene alguna duda respecto al envío, producto o si el artículo que
        requiere no se muestra en la página puede contactarnos en cualquiera de
        nuestras redes sociales.
      </div>

      <div style={rowMini}>
        Si desea factura favor de enviar sus datos fiscales a cualquiera de
        nuestros medios de contacto o al correo <b>afinazone@outlook.com</b>
      </div>

      <div style={{ ...rowMini, display: "flex", justifyContent: "center" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <a
            href="https://wa.me/524613126256 "
            style={whatsapp_float}
            target="_blank"
            rel="noopener noreferrer"
            className="whatsIcon"
          >
            <WhatsAppIcon />
          </a>
          <span> 461-312-6256 </span>
        </div>
      </div>

      <div style={rowMini}>
        Si desea conocer más detalles da{" "}
        <Link
          to="/EnviosyPagos"
          style={{ color: "blue", textDecoration: "none" }}
        >
          click aquí
        </Link>
      </div>
    </div>
  );
}
