import axios from 'axios';

// URL de la función de Netlify
const netlifyFunctionUrl = 'https://eloquent-bartik-1847d8.netlify.app/.netlify/functions/sendEmail';

// Función para enviar un correo electrónico utilizando la función de Netlify
export async function sendEmail(fromEmail, toEmail, dynamicTemplateId, dynamicTemplateData) {
  try {
    const response = await axios.post(netlifyFunctionUrl, {
      fromEmail,
      toEmail,
      dynamicTemplateId,
      dynamicTemplateData,
    });

    if (response.status === 200) {
      return { success: true, message: response.data.message };
    } else {
      return { success: false, error: response.data.error };
    }
  } catch (error) {
    console.error('Error en la solicitud:', error);
    return { success: false, error: 'Error en la solicitud' };
  }
}
